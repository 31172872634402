import React, {useEffect, useState} from 'react';
import {useOutlet} from "react-router-dom";
import {TopNav} from "./TopNav";
import { Toaster } from 'react-hot-toast';
import {useAuth} from "../hooks/useAuth";

export const Base = (props) => {
    const outlet = useOutlet();

    return (
        <>
            <Toaster/>
            <div className="container-scroller" style={{height: '100vh'}}>
                <TopNav {...props}/>
                {outlet}
            </div>
        </>
    )
}