import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaPlus, FaTimes, FaSpinner} from "react-icons/fa";
import {getCookie} from "../func/Func";


export const PaintingModal = (props) => {
    const {
        show,
        onHide,
        getPaintings,
        groups,
    } = props;
    const [title, setTitle] = useState('');
    const [artist, setArtist] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [medium, setMedium] = useState('');
    const [description, setDescription] = useState('');
    const [size_height, setSize_height] = useState('');
    const [size_width, setSize_width] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);
    const [is_sold, setIs_sold] = useState(false);
    const [is_reserved, setIs_reserved] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const groupChanged = (e) => {
        setSelectedGroup(e.target.value);
    }

    const titleChanged = (e) => {
        setTitle(e.target.value);
    }

    const artistChanged = (e) => {
        setArtist(e.target.value);
    }

    const priceChanged = (e) => {
        setPrice(e.target.value);
    }

    const imageChanged = (e) => {
        setImage(e.target.files[0]);
    }

    const mediumChanged = (e) => {
        setMedium(e.target.value);
    }

    const descriptionChanged = (e) => {
        setDescription(e.target.value);
    }

    const sizeHeightChanged = (e) => {
        setSize_height(e.target.value);
    }

    const sizeWidthChanged = (e) => {
        setSize_width(e.target.value);
    }

    const soldClicked = (e) => {
        if(is_reserved) {
            setIs_reserved(!is_reserved);
        }
        setIs_sold(!is_sold);
    }

    const reservedClicked = (e) => {
        if(is_sold) {
            setIs_sold(!is_sold);
        }
        setIs_reserved(!is_reserved);
    }

    useEffect(() => {
        if(!show) {
            setTitle('');
            setArtist('');
            setPrice('');
            setImage('');
            setMedium('');
            setDescription('');
            setSize_height('');
            setSize_width('');
            setIs_sold(false);
            setIs_reserved(false);
        }
    }, [show]);

    useEffect(() => {
        if(show) {
            if(is_reserved || is_sold) {
                setPrice('');
            }
        }
    }, [is_sold, is_reserved, show]);


    const doAddPainting = () => {
        setUploadLoading(true);
        console.log('Adding painting');
        //     send data with form data
        let data = new FormData();
        data.append('title', title);
        data.append('artist', artist);
        data.append('price', price);
        data.append('image', image);
        data.append('medium', medium);
        data.append('description', description);
        data.append('size_height', size_height);
        data.append('size_width', size_width);
        data.append('is_sold', is_sold);
        data.append('is_reserved', is_reserved);
        data.append('group_id', selectedGroup);
        fetch('/api/paintings/', {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),

            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                onHide();
                getPaintings();
                setUploadLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setUploadLoading(false);
            });


    }



    return (
        <>
            
            <Modal
                size={'xl'}
                show={show}
                onHide={onHide}
                className={"modal-blur"}
            >
                <Modal.Body className={"bg-dark"}>
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <h3 className={"text-center"}>Add Painting</h3>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingTitle">Title</label>
                                <input onInput={titleChanged} value={title} type="text" className="form-control"
                                       id="paintingTitle" placeholder="Title"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingArtist">Artist</label>
                                <input onInput={artistChanged} value={artist} type="text" className="form-control"
                                       id="paintingArtist" placeholder="Artist"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingYear">Image</label>
                                <input onInput={imageChanged} type="file" className="form-control" id="paintingYear"
                                       placeholder="Year"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingMedium">Medium</label>
                                <input onInput={mediumChanged} value={medium} type="text" className="form-control"
                                       id="paintingMedium" placeholder="Medium"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingPrice">Price</label>
                                <input onInput={priceChanged} disabled={is_sold || is_reserved} value={price}
                                       type="text" className="form-control" id="paintingPrice" placeholder="Price"/>
                            </div>
                            <div className="form-check">
                                <input onChange={soldClicked} className="form-check-input" type="checkbox" value=""
                                       id="flexCheckDefault" checked={is_sold}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Is Sold
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={reservedClicked} className="form-check-input" type="checkbox" value=""
                                       id="flexCheckChecked" checked={is_reserved}/>
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Is Reserved
                                </label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingSizeHeight">Size Height</label>
                                <input onInput={sizeHeightChanged} value={size_height} type="text"
                                       className="form-control" id="paintingSizeHeight" placeholder="Size Height"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingSizeWidth">Size Width</label>
                                <input onInput={sizeWidthChanged} value={size_width} type="text"
                                       className="form-control" id="paintingSizeWidth" placeholder="Size Width"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingGroup">Group</label>
                                <select onInput={groupChanged} className="form-control" aria-label="Default select example">
                                    <option selected disabled={true}>Open this select menu</option>
                                    {groups?.map(group => {
                                        return (
                                            <option key={group.id} value={group.id}>{group.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="paintingDescription">Description</label>
                                <textarea onInput={descriptionChanged} value={description} rows={5}
                                  style={{resize: 'vertical'}} className="form-control" id="paintingDescription"
                                  placeholder="Description"
                                />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <button onClick={doAddPainting}
                                        className={`btn bg-${uploadLoading ? 'warning' : 'success'}`}>
                                    {uploadLoading ?
                                        <FaSpinner className={"me-2"}/>
                                        :
                                        <FaPlus className={"me-2"}/>
                                    }


                                    Add Painting
                                </button>
                                <button onClick={onHide} className="btn bg-secondary ms-2">
                                    <FaTimes className={"me-2"}/>
                                    Cancel
                                </button>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const AddPaintingModal = (props) => {
    const {
        show,
    } = props;
    return (
        <>
            {show && (
                <PaintingModal
                    {...props}
                />
            )}
        </>

    );
}