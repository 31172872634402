import {FaSpinner} from "react-icons/fa6";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {getCookie} from "../func/Func";
import {Toaster, toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

export const Register = (props) => {
    const [username, setUsername] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [registerLoading, setRegisterLoading] = useState(false);
    const navigate = useNavigate();

    const doRegister = (e) => {
        e.preventDefault();
        if (username === "" || password1 === "" || password2 === "") {
            toast.error("Please fill in all fields");
            return;
        }
        if (password1 !== password2) {
            toast.error("Passwords must match");
        }
        setRegisterLoading(true);
        fetch('/api/register/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({username, password1}),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.success) {
                    toast.success("Account created, proceed to login");
                } else {
                    toast.error(data?.message);
                }
                setRegisterLoading(false);
            })
            .catch(err => {
                console.log("/api/login/", err);
                setRegisterLoading(false);
                toast.error("An error occurred");
            })
    }

    const usernameChanged = (e) => {
        setUsername(e.target.value);
    }

    const password1Changed = (e) => {
        setPassword1(e.target.value);
    }

    const password2Changed = (e) => {
        setPassword2(e.target.value);
    }

    const gotoLogin = () => {
        navigate("/login");
    }

    return (
        <>
            <Toaster/>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="row w-100 m-0">
                    <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
                        <div className="card col-lg-4 mx-auto">
                            <div className="card-body px-5 py-5">
                                <h3 className="card-title text-center mb-3">Create account</h3>
                                <form onSubmit={doRegister}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={registerLoading} onInput={usernameChanged} value={username}
                                               type="email" className="form-control text-white"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input disabled={registerLoading} onInput={password1Changed} value={password1}
                                               type={'password'}
                                               className="form-control text-white"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input disabled={registerLoading} onInput={password2Changed} value={password2}
                                               type={'password'}
                                               className="form-control text-white"/>
                                    </div>
                                    <div className="text-center">
                                        <button disabled={registerLoading} type="submit"
                                                className="btn btn-primary w-100 p-3">
                                            {registerLoading && <FaSpinner className="spin me-2"/>}
                                            Create Account
                                        </button>
                                        <div className={"mt-3"}>
                                            <span>Already have an account, login </span>
                                            <a onClick={gotoLogin} href={"#"} type="button">
                                                here
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}