import React, {useEffect, useState} from 'react';
import {useAuth} from "../hooks/useAuth";
import {AddPaintingModal} from "../modals/AddPaintingModal";
import {EditPaintingModal} from "../modals/EditPaintingModal";
import {FaPlus, FaTimes, FaPencilAlt} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";


export const Home = (props) => {
    const {authenticated, user} = useAuth(false);
    const [selectedPaintings, setSelectedPaintings] = useState([]);
    const [paintingToEdit, setPaintingToEdit] = useState(null);
    const [paintings, setPaintings] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [groups, setGroups] = useState([]);

    const [showPaintingModal, setShowPaintingModal] = useState(false);
    const [showEditPaintingModal, setShowEditPaintingModal] = useState(false);
    const navigate = useNavigate();

    const paintingModalOnHide = () => {
        setShowPaintingModal(false);
    }

    useEffect(() => {
        if (authenticated) {
            console.log("Authenticated", user);
            console.log("Superuser", user?.is_superuser);
            if(user) {
                if(!user?.is_superuser) {
                    navigate("/list/");
                }
            }else{
                navigate("/list/");
            }
        }
    }, [authenticated]);

    useEffect(() => {
        getPaintings();
        getGroups();
    }, []);

    useEffect(() => {
        getPaintings();
    }, [page]);


    const getGroups = () => {
        fetch('/api/groups/')
            .then(response => response.json())
            .then(data => {
                setGroups(data?.groups);
            })
            .catch(err => {
                console.log("/api/groups/", err);
            })
    }

    const getPaintings = () => {
        fetch(`/api/paintings/?page=${page}`)
            .then(response => response.json())
            .then(data => {
                setPaintings(data?.paintings);
                setTotalPages(data?.total_pages);
            })
            .catch(err => {
                console.log("/api/paintings/", err);
            })
    }

    const isPaintingSelected = (id) => {
        var found = false;
        selectedPaintings?.map((painting) => {
            if (painting?.id === id) {
                found = true;
            }
        });
        return found;
    }

    const paintingClicked = (painting) => {
        
        var newSelectedPaintings = [...selectedPaintings];
        var found = false;
        newSelectedPaintings = newSelectedPaintings.filter((p) => {
            if (p?.id === painting?.id) {
                found = true;
            }
            return p?.id !== painting?.id;
        });
        if (!found) {
            newSelectedPaintings.push(painting);
        }
        setSelectedPaintings(newSelectedPaintings);
    }

    const deletePaintings = () => {
        let painting_ids = [];
        selectedPaintings?.map((painting) => {
            painting_ids.push(painting?.id);
        });
        fetch('/api/paintings/', {
            method: 'DELETE',
            body: JSON.stringify({ids: painting_ids}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setSelectedPaintings([]);
                getPaintings();
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const doExport = () => {
        // requestClipboardWritePermission().then((hasPermission) => {
        //   console.log('Has Permission:', hasPermission)
        // })
        // if (selectedPaintings?.length > 1) {
        //     toast.error('Can only copy one image at a time');
        // } else {
        //     copyImageToClipboard(selectedPaintings[0]?.image)
        //         .then(() => {
        //             toast.success('Image copied to clipboard');
        //         })
        //         .catch((error) => {
        //             toast.error('Error copying image to clipboard');
        //             console.error('Error:', error);
        //         });
        // }
    }
    
    const editPaintingClicked = (e, painting) => {
        e.stopPropagation();
        setPaintingToEdit(painting);
        setShowEditPaintingModal(true);
    }
    
    
    const editPaintingModalOnHide = () => {
        setShowEditPaintingModal(false);
    }


    const PriceDisplay = ({painting}) => {
        let priceClass = "";
        let price = "";
        if(painting?.is_sold) {
            price = "SOLD";
            priceClass = "badge bg-danger";
        }else if(painting?.is_reserved) {
            price = "RESERVED";
            priceClass = "badge bg-warning";
        }else{
            let price_text = "";
            if(painting?.price) {
                price_text = `R${painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
                priceClass = "badge bg-success";
            }else{
                price_text = "NOT SPECIFIED";
                priceClass = "badge bg-secondary";
            }
            price = price_text;
        }
        return (
            <span className={priceClass}>{price}</span>
        )
    }

    const PagesDisplay = () => {
        let buttons = [];
        // add as many as 5 buttons to the array, unless there are less than 5 pages, then add all pages
        buttons.push({page: 1, text: '<<'})
        if(totalPages > 5) {
            if(page < 3) {
                for(let i = 1; i <= 5; i++) {
                    buttons.push({page: i, text: i})
                }
            }else if(page > totalPages - 2) {
                for(let i = totalPages - 4; i <= totalPages; i++) {
                    buttons.push({page: i, text: i})
                }
            }else{
                for(let i = page - 2; i <= page + 2; i++) {
                    buttons.push({page: i, text: i})
                }
            }
        }else{
            for (let i = 1; i <= totalPages; i++) {
                buttons.push({page: i, text: i})
            }
        }
        buttons.push({page: totalPages, text: '>>'})

        return (
            <div className="d-flex justify-content-center">
                {buttons?.map((button) => {
                    return (
                        <button onClick={() => setPage(button.page)} className={
                            parseInt(button.text) === page ? `btn bg-success mx-1` : `btn bg-dark mx-1`
                        }>
                            {button.text}
                        </button>
                    );
                })}
            </div>
        )

    }

    return (
        <>
            <AddPaintingModal
                show={showPaintingModal}
                onHide={paintingModalOnHide}
                getPaintings={getPaintings}
                groups={groups}
            />
            <EditPaintingModal
                show={showEditPaintingModal}
                onHide={editPaintingModalOnHide}
                getPaintings={getPaintings}
                paintingToEdit={paintingToEdit}
                setPaintingToEdit={setPaintingToEdit}
                groups={groups}
            />
            <div className="main-panel">
                <div className="content-wrapper">

                    <div className="row">
                        <div className={`col-lg-${selectedPaintings?.length > 0 ? '8' : '12'}`}>

                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <span>Paintings (Page {page.toString().length < 2 ? `0${page}`: page} of {totalPages})</span>

                                    <PagesDisplay/>
                                    <div>
                                        <button onClick={() => setShowPaintingModal(true)}
                                                className="btn bg-success btn-sm">
                                            <FaPlus className={"me-2"}/>
                                            Add Painting
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive" style={{overflowY: 'scroll', height: '80vh'}}>
                                        <table className="table table-sm table-striped table-nowrap">
                                            <thead className={"sticky-top bg-black"}>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Artist</th>
                                                <th>Title</th>

                                                <th>Height X Width</th>
                                                {/*<th>Width</th>*/}

                                                <th>Price</th>
                                                <th>Edit</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {paintings?.map((painting, index) => {
                                                const selected = isPaintingSelected(painting?.id);
                                                return (
                                                    <tr onClick={() => paintingClicked(painting)} key={index}
                                                        className={selected && "active"}>
                                                        <td>{painting?.id}</td>
                                                        <td>
                                                            {painting?.image && (
                                                                <img
                                                                    src={painting?.preview_image}
                                                                    alt={painting?.title}
                                                                    style={{width: '80px', height: '80px'}}
                                                                />
                                                            )}
                                                        </td>
                                                        <td>{painting?.artist}</td>
                                                        <td>{painting?.title}</td>

                                                        <td>{painting?.size_height}cm X {painting?.size_width}cm</td>
                                                        {/*<td>{painting?.size_width}</td>*/}

                                                        <td>
                                                            <PriceDisplay painting={painting}/>
                                                        </td>
                                                        <td className={"p-0"}>
                                                            <button onClick={e => editPaintingClicked(e, painting)} className="btn bg-success m-0">
                                                                <FaPencilAlt/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {selectedPaintings?.length > 0 && (
                            <div className="col-lg-4">
                                <div className="card mb-3 bg-dark border-success p-2">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span
                                                className={"badge badge-success"}>{selectedPaintings?.length} of {paintings?.length} Selected</span>
                                            <span onClick={deletePaintings}
                                                  className={"ms-2 btn bg-danger"}>Delete</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{overflowY: 'scroll', height: '78vh'}}>
                                    {selectedPaintings?.map((painting, index) => {
                                        return (
                                            <>
                                                <div className="card mb-3">

                                                    <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                                                        <button onClick={() => paintingClicked(painting)}
                                                                className={"btn btn-outline-danger rounded btn-sm"}>
                                                            <FaTimes/>
                                                        </button>
                                                    </div>

                                                    <div className="card-body py-0 px-4">
                                                        <div
                                                            className="d-flex justify-content-between align-items-center">
                                                            <div style={{width: '30%'}} className={"p-0"}>
                                                                <img style={{
                                                                    height: "8rem",
                                                                    maxWidth: "8rem",
                                                                    objectFit: 'contain'
                                                                }} className={"border-success p-1"}
                                                                     src={painting?.preview_image}
                                                                     alt={painting?.title}/>
                                                            </div>
                                                            <div style={{width: '70%'}} className={"py-2"}>
                                                                <h5 className={"p-0 mx-0 my-2"}><i
                                                                    className={"text-success"}>{painting?.title}</i>
                                                                </h5>
                                                                <p className={"p-0 m-0"}>{painting?.artist}</p>
                                                                <p className={"py-1 px-2 m-0 mb-2 badge badge-success"}>R{painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>

                            </div>
                        )}
                    </div>

                </div>
                {/*<footer className="footer">*/}
                {/*    <div className="d-sm-flex justify-content-center justify-content-sm-between">*/}
                {/*        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2021</span>*/}
                {/*        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a*/}
                {/*            href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span>*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </div>
        </>
    )
}