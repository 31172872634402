import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaPlus, FaTimes, FaSave} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {FaS} from "react-icons/fa6";


export const PaintingModal = (props) => {
    const {
        show,
        onHide,
        selectedPainting,
        setSelectedPainting,
        PriceDisplay,
    } = props;

    const tdStyle = {padding: '6px'};



    useEffect(() => {
        if(!show) {
            setSelectedPainting(null);
        }
    }, [show]);


    return (
        <>
            
            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                className={"modal-blur"}
            >
                <Modal.Header closeButton className={"bg-dark"}>
                  <Modal.Title>{selectedPainting?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"bg-dark"}>
                    <div className="row mt-2">

                        <div className="col-lg-12">
                            <img
                                src={selectedPainting?.image}
                                alt={selectedPainting?.title}
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className="col-lg-12 mt-3">
                            <table className="table table-borderless table-striped table-nowrap table-hover table-sm">
                                <tbody>
                                <tr>
                                    <td style={tdStyle}>Title</td>
                                    <td style={tdStyle}>{selectedPainting?.title}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Artist</td>
                                    <td style={tdStyle}>{selectedPainting?.artist}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Size</td>
                                    <td style={tdStyle}>{selectedPainting?.size_height}cm X {selectedPainting?.size_width}cm</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Price</td>
                                    {/*<td style={tdStyle}>R{selectedPainting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>*/}
                                    <td style={tdStyle}>
                                        <PriceDisplay painting={selectedPainting}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const ViewPaintingModal = (props) => {
    const {
        show,
    } = props;
    return (
        <>
            {show && (
                <PaintingModal
                    {...props}
                />
            )}
        </>

    );
}