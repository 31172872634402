import React, {useEffect, useState} from 'react';
import {useAuth} from "../hooks/useAuth";
import {AddPaintingModal} from "../modals/AddPaintingModal";
import {ViewPaintingModal} from "../modals/ViewPaintingModal";
import {FaPlus, FaTimes, FaPencilAlt} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {toast} from "react-hot-toast";


export const List = (props) => {
    const {authenticated, user} = useAuth(false);
    const [selectedPaintings, setSelectedPaintings] = useState([]);
    const [selectedPainting, setSelectedPainting] = useState(null);
    const [paintings, setPaintings] = useState([]);
    const [showViewPaintingModal, setShowViewPaintingModal] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getPaintings();
    }, []);

    useEffect(() => {
        getPaintings();
    }, [page]);

    const getPaintings = () => {
        fetch(`/api/paintings/?page=${page}`)
            .then(response => response.json())
            .then(data => {
                setPaintings(data?.paintings);
                setTotalPages(data?.total_pages);
            })
            .catch(err => {
                console.log("/api/paintings/", err);
            })
    }

    const paintingClicked = (painting) => {
        setSelectedPainting(painting);
        setShowViewPaintingModal(true);
    }


    const viewPaintingModalOnHide = () => {
        setShowViewPaintingModal(false);
    }

    const PriceDisplay = ({painting}) => {
        let priceClass = "";
        let price = "";
        if (painting?.is_sold) {
            price = "SOLD";
            priceClass = "badge bg-danger";
        } else if (painting?.is_reserved) {
            price = "RESERVED";
            priceClass = "badge bg-warning";
        } else {
            let price_text = "";
            if (painting?.price) {
                price_text = `R${painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
                priceClass = "badge bg-success";
            } else {
                price_text = "NOT SPECIFIED";
                priceClass = "badge bg-secondary";
            }
            price = price_text;
        }
        return (
            <span className={priceClass}>{price}</span>
        )
    }

    const logout = () => {
        fetch('/api/logout/').then(() => {
            window.location = "/login/";
        })
    }

    const PagesDisplay = () => {
        let buttons = [];
        // add as many as 5 buttons to the array, unless there are less than 5 pages, then add all pages
        buttons.push({page: 1, text: '<<'})
        if (totalPages > 5) {
            if (page < 3) {
                for (let i = 1; i <= 5; i++) {
                    buttons.push({page: i, text: i})
                }
            } else if (page > totalPages - 2) {
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    buttons.push({page: i, text: i})
                }
            } else {
                for (let i = page - 2; i <= page + 2; i++) {
                    buttons.push({page: i, text: i})
                }
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push({page: i, text: i})
            }
        }
        buttons.push({page: totalPages, text: '>>'})

        return (
            <div className="d-flex justify-content-center">
                {buttons?.map((button) => {
                    return (
                        <button onClick={() => setPage(button.page)} className={
                            parseInt(button.text) === page ? `btn bg-success mx-1` : `btn bg-dark mx-1`
                        }>
                            {button.text}
                        </button>
                    );
                })}
            </div>
        )

    }


    return (
        <>
            <ViewPaintingModal
                show={showViewPaintingModal}
                onHide={viewPaintingModalOnHide}
                getPaintings={getPaintings}
                selectedPainting={selectedPainting}
                setSelectedPainting={setSelectedPainting}
                PriceDisplay={PriceDisplay}
            />
            <div className="">
                <div className="">

                    <div className="row">
                        <div className={`col-lg-${selectedPaintings?.length > 0 ? '9' : '12'}`}>
                            {/*<div className={`col-lg-12`}>*/}

                            <div className="card">
                                {/*<div className="card-header d-flex justify-content-between align-items-center">*/}
                                {/*<div className="card-header d-flex justify-content-center align-items-center">*/}
                                {/*<div className={`card-header d-flex justify-content-${user?.is_authenticated ? 'between' : 'center'} align-items-center`}>*/}
                                <div className={`card-header d-flex justify-content-between align-items-center`}>
                                    {/*{user?.is_authenticated && (*/}
                                    {/*    <div></div>*/}
                                    {/*)}*/}
                                    <div className={`d-none d-md-block`}>
                                        <h3>Paintings</h3>
                                    </div>
                                    <PagesDisplay/>
                                    {user?.is_authenticated && (
                                        <div className={"d-flex align-items-center align-middle"}>
                                            <>
                                                <h5 className={"m-0 me-2"}>{user?.username}</h5>
                                                <button className="btn btn-sm btn-danger" onClick={logout}>Logout
                                                </button>
                                            </>
                                        </div>
                                    )}
                                </div>
                                <div className="card-body p-0">
                                    {/*<div className="table-responsive" style={{overflowY: 'scroll', height: '80vh'}}>*/}
                                    <div className="table-responsive" style={{overflowY: 'scroll', height: '80vh'}}>
                                        <table className="table table-sm table-striped table-nowrap">
                                            <thead className={"sticky-top bg-black"}>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Artist</th>
                                                <th>Title</th>

                                                <th>Height X Width</th>
                                                {/*<th>Width</th>*/}

                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {paintings?.map((painting, index) => {
                                                return (
                                                    <tr onClick={() => paintingClicked(painting)} key={index}>
                                                        <td>{painting?.id}</td>
                                                        <td style={{padding: '5px'}}>
                                                            {painting?.image && (
                                                                <div style={{
                                                                    borderRadius: '5px',
                                                                    width: '120px', height: '120px',
                                                                    backgroundImage: `url(${painting?.preview_image})`,
                                                                    backgroundSize: 'cover',
                                                                    backgroundPosition: 'center',
                                                                    objectFit: 'contain'
                                                                }}>

                                                                </div>
                                                                // <img
                                                                //     src={painting?.preview_image}
                                                                //     alt={painting?.title}
                                                                //     style={{width: '120px', height: '120px', borderRadius: '5px'}}
                                                                // />
                                                            )}
                                                        </td>
                                                        <td>{painting?.artist}</td>
                                                        <td>{painting?.title}</td>

                                                        <td>{painting?.size_height}cm X {painting?.size_width}cm</td>
                                                        {/*<td>{painting?.size_width}</td>*/}

                                                        <td>
                                                            <PriceDisplay painting={painting}/>
                                                            {/*R{painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}*/}
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {selectedPaintings?.length > 0 && (
                            <div className="col-lg-3">
                                {/*<div className="card mb-3 bg-dark border-success p-2">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-lg-12">*/}
                                {/*            <span*/}
                                {/*                className={"badge badge-success"}>{selectedPaintings?.length} of {paintings?.length} Selected</span>*/}
                                {/*            <span onClick={deletePaintings}*/}
                                {/*                  className={"ms-2 btn bg-danger"}>Delete</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div style={{overflowY: 'scroll', height: '78vh'}}>
                                    {selectedPaintings?.map((painting, index) => {
                                        return (
                                            <>
                                                <div className="card mb-3">

                                                    <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                                                        <button onClick={() => paintingClicked(painting)}
                                                                className={"btn btn-danger rounded"}>
                                                            <FaTimes/>
                                                        </button>
                                                    </div>

                                                    <div className="card-body py-0 px-4">
                                                        <img style={{
                                                            width: "100%",
                                                            // maxWidth: "12rem",
                                                            objectFit: 'contain'
                                                        }} className={"border-success p-1"}
                                                             src={painting?.image}
                                                             alt={painting?.title}/>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>

                            </div>
                        )}
                    </div>

                </div>
                {/*<footer className="footer">*/}
                {/*    <div className="d-sm-flex justify-content-center justify-content-sm-between">*/}
                {/*        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2021</span>*/}
                {/*        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a*/}
                {/*            href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span>*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </div>
        </>
    )
}