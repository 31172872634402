import {FaSpinner} from "react-icons/fa6";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {getCookie} from "../func/Func";
import {Toaster, toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

export const Login = (props) => {
    const {authenticated, user, checkLogin} = useAuth(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            console.log("Authenticated", user);
            console.log("Superuser", user?.is_superuser);
            if(user) {
                if(user?.is_superuser) {
                    window.location = "/";
                }else{
                    window.location = "/list/";
                }
            }else{
                window.location = "/list/";
            }
        }
    }, [authenticated]);


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const passwordChanged = (e) => {
        setPassword(e.target.value);
    }

    const usernameChanged = (e) => {
        setUsername(e.target.value);
    }

    const doLogin = (e) => {
        e.preventDefault();
        if (username === "" || password === "") {
            toast.error("Please fill in all fields");
            return;
        }
        setLoginLoading(true);
        fetch('/api/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({username, password}),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.success) {
                    checkLogin();
                }else{
                    toast.error(data?.message);
                }
                setLoginLoading(false);
            })
            .catch(err => {
                console.log("/api/login/", err);
                setLoginLoading(false);
                toast.error("An error occurred");
            })
    }

    const gotoRegister = () => {
        navigate("/register");
    }

    return (
        <>
            <Toaster/>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="row w-100 m-0">
                    <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
                        <div className="card col-lg-4 mx-auto">
                            <div className="card-body px-5 py-5">
                                <h3 className="card-title text-center mb-3">Login</h3>
                                <form onSubmit={doLogin}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={loginLoading} onInput={usernameChanged} value={username} type="text" className="form-control text-white"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input disabled={loginLoading} onInput={passwordChanged} value={password} type={showPassword?'text':'password'} className="form-control text-white"/>
                                    </div>
                                    <div className="text-center">
                                        <button disabled={loginLoading} type="submit"
                                                className="btn btn-primary w-100 p-3">
                                            {loginLoading && <FaSpinner className="spin me-2"/>}
                                            Login
                                        </button>
                                        <div className="mt-3">
                                            <span>Don't have an account, create one </span>
                                            <a onClick={gotoRegister} href={"#"} type="button">
                                                here
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}