import {useState, useEffect} from "react";
import {FaArtstation} from "react-icons/fa";
import {FaA, FaR, FaT} from "react-icons/fa6";

import {
    Link,
} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import Nav from "./Nav";

export const TopNav = (props) => {
    const {authenticated, user} = useAuth();
    
    const logout = () => {
        fetch('/api/logout/').then(() => {
            window.location = "/login/";
        })
    }
    
    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div
                    className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <span className={"sidebar-brand brand-logo"}>
                        <FaA/>
                        <FaR/>
                        <FaT/>
                    </span>
                    <span className={"sidebar-brand brand-logo-mini"}>
                        <FaA/>
                    </span>
                    {/*<a className="sidebar-brand brand-logo" href="../../index.html"><img*/}
                    {/*    src="../../assets/images/logo.svg" alt="logo"/></a>*/}
                    {/*<a className="sidebar-brand brand-logo-mini" href="../../index.html"><img*/}
                    {/*    src="../../assets/images/logo-mini.svg" alt="logo"/></a>*/}
                </div>
                <ul className="nav">
                    <li className="nav-item profile">
                        <div className="profile-desc">
                            <div className="profile-pic">
                                <div className="count-indicator">
                                    <img className="img-xs rounded-circle " src="../../assets/images/faces/face15.jpg"
                                         alt=""/>
                                    <span className="count bg-success"></span>
                                </div>
                                <div className="profile-name">
                                    <h5 className="mb-0 font-weight-normal">{user?.username}</h5>
                                    {/*<span>Gold Member</span>*/}
                                </div>
                            </div>
                            <a href="#" id="profile-dropdown" data-bs-toggle="dropdown">
                                <i className="mdi mdi-dots-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list"
                                 aria-labelledby="profile-dropdown">
                                <a onClick={logout} href="#" className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-dark rounded-circle">
                                            <i className="mdi mdi-logout text-danger"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <p className="preview-subject ellipsis mb-1 text-small">Logout</p>
                                    </div>
                                </a>
                                {/*<div className="dropdown-divider"></div>*/}
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-category">
                        <span className="nav-link">Navigation</span>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" href="/">
                          <span className="menu-icon">
                            {/*<i className="mdi mdi-speedometer"></i>*/}
                              <FaArtstation/>
                          </span>
                            <span className="menu-title">Paintings</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <Nav {...props} user={user} logout={logout}/>
        </>
    )
}