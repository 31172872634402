import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaPlus, FaTimes, FaSave} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {FaS} from "react-icons/fa6";


export const PaintingModal = (props) => {
    const {
        show,
        onHide,
        getPaintings,
        paintingToEdit,
        setPaintingToEdit,
        groups,
    } = props;

    
    const titleChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, title: e.target.value});
    }

    const artistChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, artist: e.target.value});
    }

    const priceChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, price: e.target.value});
    }

    const imageChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, image: e.target.files[0]});
    }

    const mediumChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, medium: e.target.value});
    }

    const descriptionChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, description: e.target.value});
    }

    const sizeHeightChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, size_height: e.target.value});
    }

    const sizeWidthChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, size_width: e.target.value});
    }

    const groupChanged = (e) => {
        setPaintingToEdit({...paintingToEdit, group: e.target.value});
    }

    useEffect(() => {
        if(!show) {
            setPaintingToEdit(null);
        }
    }, [show]);


    const doAddPainting = () => {
        console.log('Adding painting');
        //     send data with form data
        let data = new FormData();
        data.append('id', paintingToEdit?.id);
        data.append('title', paintingToEdit?.title);
        data.append('artist', paintingToEdit?.artist);
        data.append('price', paintingToEdit?.price);
        data.append('image', paintingToEdit?.image);
        data.append('medium', paintingToEdit?.medium);
        data.append('description', paintingToEdit?.description);
        data.append('size_height', paintingToEdit?.size_height);
        data.append('size_width', paintingToEdit?.size_width);
        data.append('is_sold', paintingToEdit?.is_sold);
        data.append('is_reserved', paintingToEdit?.is_reserved);
        data.append('group', paintingToEdit?.group);
        fetch('/api/paintings/', {
            method: 'PUT',
            body: data,
            headers: {
                'Accept': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),

            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setPaintingToEdit(null);
                onHide();
                getPaintings();
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }


    const soldClicked = (e) => {
        if(paintingToEdit?.is_reserved) {
            setPaintingToEdit({...paintingToEdit, is_reserved: false, is_sold: !paintingToEdit?.is_sold});
        }else{
            setPaintingToEdit({...paintingToEdit, is_sold: !paintingToEdit?.is_sold});
        }

    }

    const reservedClicked = (e) => {
        if(paintingToEdit?.is_sold) {
            setPaintingToEdit({...paintingToEdit, is_sold: false, is_reserved: !paintingToEdit?.is_reserved});
        }else{
            setPaintingToEdit({...paintingToEdit, is_reserved: !paintingToEdit?.is_reserved});
        }
    }



    return (
        <>
            
            <Modal
                size={'xl'}
                show={show}
                onHide={onHide}
                className={"modal-blur"}
            >
                <Modal.Body className={"bg-dark"}>
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <h3 className={"text-center"}>Edit Painting</h3>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingTitle">Title</label>
                                <input onInput={titleChanged} value={paintingToEdit?.title} type="text"
                                       className="form-control" id="paintingTitle" placeholder="Title"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingArtist">Artist</label>
                                <input onInput={artistChanged} value={paintingToEdit?.artist} type="text"
                                       className="form-control" id="paintingArtist" placeholder="Artist"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingYear">Image</label>
                                <input onInput={imageChanged} type="file" className="form-control" id="paintingYear"
                                       placeholder="Year"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingMedium">Medium</label>
                                <input onInput={mediumChanged} value={paintingToEdit?.medium} type="text"
                                       className="form-control" id="paintingMedium" placeholder="Medium"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingPrice">Price</label>
                                <input disabled={paintingToEdit?.is_sold || paintingToEdit?.is_reserved}
                                       onInput={priceChanged} value={paintingToEdit?.price} type="text"
                                       className="form-control" id="paintingPrice" placeholder="Price"/>
                            </div>

                            <div className="form-check">
                                <input onChange={soldClicked} className="form-check-input" type="checkbox" value=""
                                       id="flexCheckDefault" checked={paintingToEdit?.is_sold}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Is Sold
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={reservedClicked} className="form-check-input" type="checkbox" value=""
                                       id="flexCheckChecked" checked={paintingToEdit?.is_reserved}/>
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Is Reserved
                                </label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingSizeHeight">Size Height</label>
                                <input onInput={sizeHeightChanged} value={paintingToEdit?.size_height} type="text"
                                       className="form-control" id="paintingSizeHeight" placeholder="Size Height"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingSizeWidth">Size Width</label>
                                <input onInput={sizeWidthChanged} value={paintingToEdit?.size_width} type="text"
                                       className="form-control" id="paintingSizeWidth" placeholder="Size Width"/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="paintingGroup">Group</label>
                                <select onInput={groupChanged} className="form-control"
                                        aria-label="Default select example">
                                    {!paintingToEdit?.group && (
                                        <option selected disabled>Choose a group</option>
                                    )}
                                    {groups?.map(group => {
                                        let selected = paintingToEdit?.group?.id === group.id;
                                        return (
                                            <option selected={selected} key={group.id} value={group.id}>{group.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="paintingDescription">Description</label>
                                <textarea onInput={descriptionChanged} value={paintingToEdit?.description} rows={5}
                                          style={{resize: 'vertical'}} className="form-control" id="paintingDescription"
                                          placeholder="Description"/>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <button onClick={doAddPainting} className={"btn bg-success"}>
                                    <FaSave className={"me-2"}/>
                                    Save Painting
                                </button>
                                <button onClick={onHide} className="btn bg-secondary ms-2">
                                    <FaTimes className={"me-2"}/>
                                    Cancel
                                </button>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const EditPaintingModal = (props) => {
    const {
        show,
    } = props;
    return (
        <>
            {show && (
                <PaintingModal
                    {...props}
                />
            )}
        </>

    );
}