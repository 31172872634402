import {useEffect, useState} from "react";

export function useAuth(redirect=true) {
    const [user, setUser] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = () => {
        fetch('/api/user/')
            .then(response => response.json())
            .then(data => {
                if (data?.is_authenticated === false) {
                    console.log("Not authenticated");
                    redirect && logout();
                }
                setAuthenticated(data?.is_authenticated);
                setUser(data);
            })
            .catch(err => {
                console.log("/api/user/", err);
            })
    }

    const logout = () => {
        window.location = "/login/";
    };

    return {authenticated, user, checkLogin, logout};
}