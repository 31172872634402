import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes,
} from "react-router-dom";
import {Home} from "./pages/Home";
import {List} from "./pages/List";
import {CardList} from "./pages/CardList";
import {Base} from "./components/Base";
import {Register} from "./pages/Register";
// import {Members} from "./pages/Members";
import {NotFound} from "./pages/NotFound";
import {Login} from "./pages/Login";
import './App.css';

export const MainApp = (props) => {
     const [theme, setTheme] = useState('light');

    useEffect(() => {
        const local_storage_theme = localStorage.getItem('tablerTheme');
        if (local_storage_theme) {
            setTheme(local_storage_theme);
        } else {
            setTheme('light');
            localStorage.setItem('tablerTheme', 'light');
            document.body.setAttribute('data-bs-theme', 'light');
        }
    }, [])

    const themeChanged = () => {
        var newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('tablerTheme', newTheme);
        document.body.setAttribute('data-bs-theme', newTheme);
    }

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Base theme={theme} themeChanged={themeChanged}/>}>
                        <Route path={""} element={<Home theme={theme}/>}/>

                        {/*<Route path={"members"} element={<Members theme={theme}/>}/>*/}
                    </Route>
                    <Route path="/list" element={<List theme={theme}/>}/>
                    <Route path="/cardlist" element={<CardList theme={theme}/>}/>
                    <Route path="/login" element={<Login theme={theme}/>}/>
                    <Route path="/register" element={<Register theme={theme}/>}/>
                    <Route path="*" element={<NotFound theme={theme}/>}/>
                </Routes>
            </Router>


        </>
    );
}


