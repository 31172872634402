import React, {useEffect, useState} from 'react';
import {useAuth} from "../hooks/useAuth";
import {AddPaintingModal} from "../modals/AddPaintingModal";
import {EditPaintingModal} from "../modals/EditPaintingModal";
import {FaPlus, FaTimes, FaPencilAlt} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {toast} from "react-hot-toast";
import {ViewPaintingModal} from "../modals/ViewPaintingModal";

export const CardList = (props) => {
    const [selectedPaintings, setSelectedPaintings] = useState([]);
    const [paintingToEdit, setPaintingToEdit] = useState(null);
    const [paintings, setPaintings] = useState([]);
    const [showPaintingModal, setShowPaintingModal] = useState(false);
    const [showEditPaintingModal, setShowEditPaintingModal] = useState(false);
    const [selectedPainting, setSelectedPainting] = useState(null);
    const [showViewPaintingModal, setShowViewPaintingModal] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const paintingModalOnHide = () => {
        setShowPaintingModal(false);
    }

    useEffect(() => {
        getPaintings();
    }, []);

    useEffect(() => {
        getPaintings();
    }, [page]);

    const getPaintings = () => {
        fetch(`/api/paintings/?page=${page}`)
            .then(response => response.json())
            .then(data => {
                setPaintings(data?.paintings);
                setTotalPages(data?.total_pages);
            })
            .catch(err => {
                console.log("/api/paintings/", err);
            })
    }

    const paintingClicked = (painting) => {
        setSelectedPainting(painting);
        setShowViewPaintingModal(true);
    }


    const viewPaintingModalOnHide = () => {
        setShowViewPaintingModal(false);
    }

    const PriceDisplay = ({painting}) => {
        let priceClass = "";
        let price = "";
        if(painting?.is_sold) {
            price = "SOLD";
            priceClass = "badge bg-danger";
        }else if(painting?.is_reserved) {
            price = "RESERVED";
            priceClass = "badge bg-warning";
        }else{
            let price_text = "";
            if(painting?.price) {
                price_text = `R${painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
                priceClass = "badge bg-success";
            }else{
                price_text = "NOT SPECIFIED";
                priceClass = "badge bg-secondary";
            }
            price = price_text;
        }
        return (
            <span className={priceClass}>{price}</span>
        )
    }

    const PagesDisplay = () => {
        let buttons = [];
        // add as many as 5 buttons to the array, unless there are less than 5 pages, then add all pages
        buttons.push({page: 1, text: '<<'})
        if(totalPages > 5) {
            if(page < 3) {
                for(let i = 1; i <= 5; i++) {
                    buttons.push({page: i, text: i})
                }
            }else if(page > totalPages - 2) {
                for(let i = totalPages - 4; i <= totalPages; i++) {
                    buttons.push({page: i, text: i})
                }
            }else{
                for(let i = page - 2; i <= page + 2; i++) {
                    buttons.push({page: i, text: i})
                }
            }
        }else{
            for (let i = 1; i <= totalPages; i++) {
                buttons.push({page: i, text: i})
            }
        }
        buttons.push({page: totalPages, text: '>>'})

        return (
            <div className="d-flex justify-content-center">
                {buttons?.map((button) => {
                    return (
                        <button onClick={() => setPage(button.page)} className={
                            parseInt(button.text) === page ? `btn bg-success mx-1` : `btn bg-dark mx-1`
                        }>
                            {button.text}
                        </button>
                    );
                })}
            </div>
        )

    }


    return (
        <>
            <ViewPaintingModal
                show={showViewPaintingModal}
                onHide={viewPaintingModalOnHide}
                getPaintings={getPaintings}
                selectedPainting={selectedPainting}
                setSelectedPainting={setSelectedPainting}
                PriceDisplay={PriceDisplay}
            />
            <div className="">
                <div className="content-wrapper">

                    <div className="row">

                        <div className="col-lg-12 mb-3">
                            <div className="d-flex justify-content-center">
                                {/*<h3>Paintings</h3>*/}
                                <PagesDisplay/>
                            </div>
                        </div>

                        {paintings?.map((painting, index) => {
                            const tdStyle = {padding: '6px'};
                            return (
                                <div className="col-lg-3 mb-3">
                                    <div className="card">
                                        <div className="card-header bg-dark">
                                            <h3 className={"text-center mb-0"}>
                                                {painting?.title}
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <img
                                                onClick={() => paintingClicked(painting)}
                                                src={painting?.preview_image}
                                                alt={painting?.title}
                                                style={{width: '100%'}}
                                            />
                                        </div>
                                        <div className="card-footer bg-dark">
                                            <table className="table table-borderless table-striped table-nowrap table-hover table-sm">
                                                <tbody>
                                                <tr>
                                                    <td style={tdStyle}>Title</td>
                                                    <td style={tdStyle}>{painting?.title}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdStyle}>Artist</td>
                                                    <td style={tdStyle}>{painting?.artist}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdStyle}>Size</td>
                                                    <td style={tdStyle}>{painting?.size_height}cm X {painting?.size_width}cm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdStyle}>Price</td>
                                                    {/*<td style={tdStyle}>R{painting?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>*/}
                                                    <td style={tdStyle}>
                                                        <PriceDisplay painting={painting}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>




                                        </div>
                                    </div>
                                </div>
                            )

                        })}


                    </div>

                </div>
                {/*<footer className="footer">*/}
                {/*    <div className="d-sm-flex justify-content-center justify-content-sm-between">*/}
                {/*        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2021</span>*/}
                {/*        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a*/}
                {/*            href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span>*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </div>
        </>
    )
}